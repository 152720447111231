/* @tailwind base;
@tailwind components;
@tailwind utilities; */

body {
  margin: 0;
  overflow-x: hidden;
  font-family: "futura", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grid {
  display: grid;
  grid-template-columns: 5vw 15vw 15vw 15vw 15vw 15vw 15vw 5vw;
  grid-template-rows: 40pt 1fr 45vw 20pt 50vw 70pt;
}

.grid-static {
  display: grid;
  grid-template-columns: 5vw 15vw 15vw 15vw 15vw 15vw 15vw 5vw;
  grid-template-rows: 40pt 1fr;
}

.header {
  grid-column: 1/8;
  grid-row: 1/2;
  font-size: 2em;
  font-weight: 500;
  background-color: #467EE5;
  color: white;
  text-align: center;
  line-height: 0em;
}

/* .logo {
  background-image: url(/src/maplogo.png);
}
*/

/* Add a black background color to the top navigation */

.topnav {
  position: fixed;
  z-index: 1000;
  width: 100%;
  font-size: 2em;
  font-weight: 500;
  background-color: #A4CAFA;
}

/* Style the links inside the navigation bar */
.topnav a {
  vertical-align: middle;
  color: #f2f2f2;
  text-align: center;
  /*padding: 14px 16px; */
  text-decoration: none;
  font-size: 17px;
  font-family: "Heebo", sans-serif;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: #152F67;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #467EE5;
  color: white;
}

.banner-logo {
  float: left;
  padding: 2px 2px;
  height: 50px;
  width: auto;
}
#banner-text {
  float: left;
  font-weight: 700;
  font-size: 25px;
  padding: 12px 11.5px;
}

#link {
  float: right;
  font-weight: 600;
  padding: 16.5px 13.5px;
}

#pdf {
  padding: 5px;
  top: 5px;
  right: 5px;
  margin: 7.5px;
  float:right;
  background: #152E66;
  color: #F2F2F2;
  border-radius: 5px;
  color: #ffffff;
  font-size: 17px;
  border-color: transparent;
}



.footer {
  grid-column: 2/8;
  grid-row: 8/9;
  text-align: center;
  align-content: bottom;
  margin-top: 10px;

}

#bmc-wbtn{
  bottom: 15px;
}

#bmc-wbtn + div{
  bottom:15px;
}

.buyMeACoffee {
  grid-column: 1/8;
  grid-row: 7/8;
  text-align: center;
  align-content: bottom;
  margin-top: 10px;
}

.area-selector {
  grid-column: 2/8;
  grid-row: 2/3;
  line-height: 2em;
  text-align: center;
  display: grid;
  margin: 0;
  grid-template-columns: 5em 1fr 1fr 1fr 1fr 48px 5em;
  grid-template-rows: 1em 14pt 28pt 14pt 1em;
}

.area-selector.broadband {
  grid-template-rows: 1em 14pt 28pt 14pt 15pt 28pt 1em;
}

.loading-icon {
  grid-column:6/7;
  grid-row:3/4;
  text-align: center;
}

.area-selector-title {
  grid-column: 3/5;
  grid-row: 2/3;
  text-align: center;
}

.area-selector-label-datatype {
  grid-column: 2/3;
  grid-row: 3/4;
  text-align: center;
}

.area-selector-label-geography {
  grid-column: 3/4;
  grid-row: 3/4;
  text-align: center;
}

.area-selector-label-variable {
  grid-column: 4/5;
  grid-row: 3/4;
  text-align: center;
}

.area-selector-label-year {
  grid-column: 5/6;
  grid-row: 3/4;
  text-align: center;
}

.area-selector-label-state {
  grid-column: 2/6;
  grid-row: 5/6;
  text-align: center;
  color: red;

}

.area-selector-dropdown-datatype {
  grid-column: 2/3;
  grid-row: 4/5;
  text-align: center;
}

.area-selector-dropdown-geography {
  grid-column: 3/4;
  grid-row: 4/5;
  text-align: center;
}

.area-selector-dropdown-variable {
  grid-column: 4/5;
  grid-row: 4/5;
  text-align: center;
}

.area-selector-dropdown-year {
  grid-column: 5/6;
  grid-row: 4/5;
  text-align: center;
}

.area-selector-dropdown-state {
  grid-column: 3/4;
  grid-row: 5/6;
  align-items: center;
  margin-top: 20px;
}

.area-selector-updown {
  grid-column: 2/6;
  grid-row: 6/8;
  text-align: center;
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.map-broadband{
  grid-column: 1/2;
  grid-row: 1/3;
  display: grid;
  margin: 0;
  grid-template-columns: 2fr 2fr;
  grid-template-rows: 1fr 1fr;
}

.map-broadband-left{
  grid-column: 1/2;
  grid-row: 1/3;
}

.map-broadband-right{
  grid-column: 2/3;
  grid-row: 1/3;
}

.map-broadband-button{
  grid-column: 2/3;
  grid-row: 1/2;
  align-content: center;
}

.mapAndKey {
  grid-column: 2/6;
  grid-row: 3/4;
  display: grid;
  grid-template-columns: repeat(100, 1fr);
  grid-template-rows: repeat(100, 1fr);
}

.chart-container-left{
  grid-column: 6/8;
  grid-row: 3/4;
}

.chart-container-right{
  grid-column: 3/7;
  grid-row: 5/7;
  z-index: 10;
}

@media (max-width: 1000px) {
  .area-selector {
    grid-template-columns: 5em 1fr 1fr 48px 5em;
    grid-template-rows: 1em 14pt 28pt 14pt 28pt 14pt 1em;
  }

  .area-selector.broadband {
    grid-template-rows: 1em 14pt 28pt 14pt 28pt 14pt 28pt 28pt 1em;
  }

  .area-selector-title{
    grid-column: 2/4;
  }

  .area-selector-label-variable{
    grid-column: 2/3;
    grid-row: 5/6;
  }

  .area-selector-label-year{
    grid-column: 3/4;
    grid-row: 5/6;
  }

  .area-selector-dropdown-variable{
    grid-column: 2/3;
    grid-row: 6/7;
  }

  .area-selector-dropdown-year{
    grid-column: 3/4;
    grid-row: 6/7;
  }

  .area-selector-updown{
    grid-column: 2/4;
    grid-row: 7/9;
  }

  .map-broadband{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .map-broadband-left{
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .map-broadband-right{
    grid-column: 1/2;
    grid-row: 2/3;
  }

  .loading-icon {
    grid-column:4/5;
  }

  .footer {
    margin-top: 20px;
  }

}

@media (max-width: 700px) {
  .grid {
    display: grid;
    grid-template-columns: 5vw 15vw 15vw 15vw 15vw 15vw 15vw 5vw;
    grid-template-rows: 40pt 1fr 45vw 45vw 20pt 60vw 120pt;
  }

  .area-selector {
    grid-template-columns: 5em 1fr 48px 5em;
    grid-template-rows: 1em 14pt 28pt 14pt 28pt 14pt 28pt 14pt 28pt 14pt 1em;
  }

  .area-selector.broadband {
    grid-template-rows: 1em 14pt 28pt 14pt 28pt 14pt 28pt 28pt 28pt 28pt 28pt 28pt 28pt 1em;
  }

  .area-selector-title{
    grid-column: 2/3;
  }

  .area-selector-label-datatype {
    grid-column: 2/3;
    grid-row: 3/4;
    text-align: center;
  }
  
  .area-selector-label-geography {
    grid-column: 2/3;
    grid-row: 5/6;
    text-align: center;
  }
  
  .area-selector-label-variable {
    grid-column: 2/3;
    grid-row: 7/8;
    text-align: center;
  }
  
  .area-selector-label-year {
    grid-column: 2/3;
    grid-row: 9/10;
  }
  
  .area-selector-dropdown-datatype {
    grid-column: 2/3;
    grid-row: 4/5;
  }
  
  .area-selector-dropdown-geography {
    grid-column: 2/3;
    grid-row: 6/7;
  }
  
  .area-selector-dropdown-variable {
    grid-column: 2/3;
    grid-row: 8/9;
  }
  
  .area-selector-dropdown-year {
    grid-column: 2/3;
    grid-row: 10/11;
  }

  .area-selector-updown{
    grid-column: 2/3;
    grid-row: 12/13;
  }

  .loading-icon {
    grid-column:3/4;
  }

  .footer {
    grid-row: 8/9;
  }

  .mapAndKey{
    grid-column: 2/7;
  }

  .chart-container-left {
    grid-column: 2/7;
    grid-row: 4/5;
  }

  .chart-container-right {
    grid-column: 2/7;
    grid-row: 6/8;
  }
}

@media (max-width: 600px) {
  .grid {
    grid-template-rows: 40pt 1fr 45vw 45vw 20pt 60vw 200pt;
  }
}

@media (max-width: 500px) {
  .grid {
    grid-template-rows: 40pt 1fr 45vw 45vw 20pt 60vw 250pt;
  }
}

@media (max-width: 400px) {
  .grid {
    grid-template-rows: 40pt 1fr 45vw 20pt 60vw 300pt;
  }
}

.map {
  grid-column: 1/100;
  grid-row: 1/100;
}

.key {
  grid-column: 70/96;
  grid-row: 89/98;
  background-color: rgba(255,255,255,0.8);
  border-radius: 0.5em;
  padding: 0.25em;
  z-index: 10;
  max-width: 15em;
  display: grid;
  grid-template-columns: 2px 1fr 1fr 1fr 1fr 2px;
  grid-template-rows: 2px 2fr 1fr 2px;
}

.keyColor1 {
  background-color: #F7FBFF;
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  overflow: hidden;
  grid-column: 2/3;
  grid-row: 2/3;
}

.keyColor2 {
  background-color: #ADD0E6;
  overflow: hidden;
  grid-column: 3/4;
  grid-row: 2/3;
}

.keyColor3 {
  background-color: #3A89C1;
  overflow: hidden;
  grid-column: 4/5;
  grid-row: 2/3;
}

.keyColor4 {
  background-color: #08306B;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  overflow: hidden;
  grid-column: 5/6;
  grid-row: 2/3;
}

.keyMin {
  grid-column: 2/3;
  grid-row: 3/4;
  max-height: 3px;
}

.keyMax {
  grid-column: 2/6;
  grid-row: 3/4;
  max-height: 3px;
  text-align: right;
  align-content: right;
}

.selector-title{
  grid-column : 2/3;
  grid-row: 2/3;
  line-height: 0em;
}

.graph-selector{
  grid-column: 3/4;
  grid-row: 2/3;
  line-height: 2em;
}

.var-selector-left{
  grid-column: 2/3;
  grid-row: 3/4;
}

.var-selector-right{
  grid-column: 3/4;
  grid-row: 3/4;
}

.scatter-chart-grid {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.scatter-chart-grid.xBroadband{
  grid-template-rows: 1em 28pt 248pt 1fr 5em;
}

.scatter-chart-grid.yBroadband{
  grid-template-rows: 1em 28pt 248pt 1fr 5em;
}

.scatter-chart-grid.xBroadband.yBroadband{
  grid-template-rows: 1em 28pt 248pt 1fr 5em;
}

.timeseries-chart-grid {
  display: grid;
  grid-template-columns: 1em 1fr 1em;
  grid-template-rows: 1em 12pt 1 10pt 14pt 14pt 1fr 1em;
}

.simple-graph {
  display: grid;
  grid-template-columns: 1em 29vw 40vw 29vw 1em;
  grid-template-rows: 1em 1fr 1em;
}

.scatter-chart{
  grid-column: 2/4;
  grid-row: 4/5;
  margin: 0;
}

.scatter-selector-title{
  grid-column: 2/4;
  grid-row: 2/3;
  text-align: center;
}
.variable-view-navigator {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  border-bottom: solid black 0.5px;
  margin-bottom: 7px;
  width: 50%;
  position: relative;
  left: 25%;
}

.variable-view-navigator > button {
  background-color: white;
  border: solid black 0.5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  margin: 0 2px;
}

.variable-view-navigator > button:hover {
  background-color: gainsboro;
}

.selected-difficulty {
  background-color: lightskyblue !important;
  color: white;
}

.simple-variable {
  line-height: 30px;
  text-align: center;
}

.simple-variable > div {
  display: inline-block;
}

.x-y-variables {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}
.x-y-variables select {
  width: 100%;
  padding: 0 2px;
}
.scatter-x-variable, .scatter-y-variable {
  padding: 6px;
  border-radius: 15px;
  border: solid black .5px;
  width: 300px;
  margin-bottom: 7px;
}

.scatter-x-variable{
  grid-column: 3/4;
  grid-row: 3/4;
  display: grid;
  text-align: center;
  grid-template-columns: 1fr;
  grid-template-rows: 14pt 28pt 14pt 28pt 14pt 28pt 14pt 28pt 14pt;
}

.scatter-y-variable{
  grid-column: 2/3;
  grid-row: 3/4;
  display: grid;
  text-align: center;
  grid-template-columns: 1fr;
  grid-template-rows: 14pt 28pt 14pt 28pt 14pt 28pt 14pt 28pt 14pt;
}

.scatter-x-variable.broadband .scatter-y-variable.broadband{
  grid-template-rows: 14pt 28pt 14pt 28pt 14pt 28pt 14pt 28pt 14pt 28pt 56pt;
}

.scatter-updown{
  grid-column: 1/2;
  grid-row: 11/12;
}

.scatter-broadband{
  display: grid;
  margin: 0;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
}

.scatter-broadband-left{
  margin: 5px;
  grid-column: 1/2;
  grid-row: 1/2;
}

.scatter-broadband-right{
  margin: 5px;
  grid-column: 1/2;
  grid-row: 2/3;
}

.scatter-x-variable-title{
  grid-column: 1/2;
  grid-row: 1/2;
  text-align: center;
}

.scatter-y-variable-title{
  grid-column: 1/2;
  grid-row: 1/2;
  text-align: center;
}

.scatter-x-data-title{
  grid-column: 1/2;
  grid-row: 2/3;
}

.scatter-x-data-dropdown{
  grid-column: 1/2;
  grid-row: 3/4;
}

.scatter-x-variable-title{
  grid-column: 1/2;
  grid-row: 4/5;
}

.scatter-x-variable-dropdown{
  grid-column: 1/2;
  grid-row: 5/6;
}

.scatter-x-time-title{
  grid-column: 1/2;
  grid-row: 6/7;
}

.scatter-x-time-dropdown{
  grid-column: 1/2;
  grid-row: 7/8;
}

.scatter-y-data-title{
  grid-column: 1/2;
  grid-row: 2/3;
}

.scatter-y-data-dropdown{
  grid-column: 1/2;
  grid-row: 3/4;
}

.scatter-y-area-title{
  grid-column: 1/2;
  grid-row: 4/5;
}

.scatter-y-area-dropdown{
  grid-column: 1/2;
  grid-row: 5/6;
}

.scatter-y-variable-title{
  grid-column: 1/2;
  grid-row: 6/7;
}

.scatter-y-variable-dropdown{
  grid-column: 1/2;
  grid-row: 7/8;
}

.scatter-y-time-title{
  grid-column: 1/2;
  grid-row: 8/9;
}

.scatter-y-time-dropdown{
  grid-column: 1/2;
  grid-row: 9/10;
}

.regression-table{
  grid-column: 2/4;
  grid-row: 5/6;
}

.timeseries-chart{
  grid-column: 2/3;
  grid-row: 7/8;
  position: relative;
  margin-top: 5px;
}

.simple-timeseries-chart{
  grid-column: 3/4;
  grid-row: 2/3;
  position: relative;
  margin-top: 5px;
}

.timeseries-selector-title{
  grid-column: 2/3;
  grid-row: 2/3;
  text-align: center;
  padding: 0.25em;
  font-size: 10pt;
}

.timeseries-selector-description{
  grid-column: 2/3;
  grid-row: 3/4;
  text-align: center;
  font-size: 10pt;
}

.timeseries-clear{
  grid-column: 2/3;
  grid-row: 5/6;
  padding: 3pt;
  font-size: 10pt;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeseries-download-button{
  grid-column: 2/3;
  grid-row: 6/7;
  padding: 3pt;
  font-size: 10pt;
  display: flex;
  align-items: center;
  justify-content: center;
}


.scatter{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40pt 1fr;
}
.scatterInfo{
  grid-column: 1/2;
  grid-row: 1/2;
  font-size: 10pt;
  line-height: 0;
  margin-bottom: 0;
  text-align: center;
}

.scatterPlot{
  grid-column: 1/2;
  grid-row: 2/3;
}

.graph-data-selector {
  grid-column: 2/3;
  grid-row: 2/3;
  display: grid;
  grid-template-columns: 2em 1fr 1fr 1em;
  grid-template-rows: 1em 1fr 7fr 1em;
}

.popup-node {
  overflow: auto;
  max-height: 10vw;
  max-width: 20vw;
  
}

.popup-p {
  margin-top: 0;
  margin-bottom: 0;
}

table {
  border-collapse:collapse;
  text-align: right;
  margin-bottom: 30px;
  border: 1px solid black;
  width: 100%;
}
th {
  background-color: lightblue;
  padding-left: 10px;
  padding-right: 10px;
  border-top: 1px solid black;
}
/* need to figure out how to have these for the regresison table but not for the simple table but I just want to see if Scott like it */
/* th:first-child{
  background:white;
  text-align: left;
  border-left: hidden;
  border-top: hidden;
  border-right: 1px solid black;
}
td:first-child{
  text-align: left;
  padding-left: 20px;
  border-left: hidden;
  border-bottom: hidden;
  border-right: 1px solid black;
} */

.simple-table-td{
  text-align: left;
  padding-left: 20px;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.map-click-tip-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(190, 189, 189, 0.8);
}

.map-click-tip {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}

.source-content {
  grid-column: 2/8;
  grid-row: 2/6;
}

.summited {
  grid-column: 4/6;
  grid-row: 2/3;
  text-align: center;
}

.mainsignin {
  grid-column: 4/6;
  grid-row: 2/3;
  display: grid;
  align-content: center;
  grid-template-columns: 1fr;
  grid-template-rows: 20pt 1fr;
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.staticstate {
  grid-column: 2/7;
  grid-row: 2/5;
  display: grid;
  align-content: center;
  grid-template-columns: 30vw 30vw 30vw;
  grid-template-rows: 20pt 20pt 40pt 35pt 1fr 35pt 30vw 1pt 40pt;
}

.header-state{
  grid-column:1/4;
  grid-row:2/3;
  text-align-last:"center";
  text-align: center;
  font-size: 2em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.header-title {
  grid-column:1/4;
  grid-row:3/4;
  text-align: center;
}

.state-map-title{
  grid-column:1/4;
  grid-row:5/6;
  text-align: center;
  margin-bottom: 5pt;
}

.state-map {
  grid-column: 1/4;
  grid-row: 5/6;
  /* height: 29vw;
  width: 40vw; */
  /* height: 50%; */
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  /* align-content: center; */
  align-items: center;
  justify-content: center;
}

.availability-header {
  grid-column: 1/2;
  grid-row: 6/7;
  text-align: center;
  margin-bottom: 15pt;
}

.adoption-header {
  grid-column: 2/3;
  grid-row: 6/7;
  text-align: center;
  margin-bottom: 15pt;
}

.broadband-header {
  grid-column: 3/4;
  grid-row: 6/7;
  text-align: center;
  margin-bottom: 15pt;
}

.availability-graph {
  grid-column: 1/2;
  grid-row: 7/8;
  /* height: 29vw; */
  width: 29vw;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.adoption-graph {
  grid-column: 2/3;
  grid-row: 7/8;
  /* height: 29vw; */
  width: 29vw;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.broadband-graph {
  grid-column: 3/4;
  grid-row: 7/8;
  /* height: 29vw; */
  width: 29vw;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.full-site {
  grid-column: 2/3;
  grid-row: 4/5;
  background-color: #467EE5;
  border: none;
  color: white;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 25px;
  z-index: 5;
}

.tribal-view-toggle {
  background-color: #467EE5;
  border: none;
  color: white;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 25px;
  z-index: 5;
}

.notes {
  grid-column: 1/4;
  grid-row: 8/9;
  text-align: center;
  font-size: smaller;
}

@media (max-width: 1050px) {
  .staticstate {
    grid-template-columns: 30vw 30vw 30vw;
    grid-template-rows: 20pt 40pt 50pt 1fr 25pt 21vw 1pt 40pt;
  }
}

@media (max-width: 900px) {

  .staticstate {
    grid-template-columns: 90vw;
    grid-template-rows: 20pt 40pt 50pt 1fr 25pt 65vw 25pt 65vw 25pt 65vw 1pt 40pt;
  }

  .header-state{
    grid-column:1/2;
  }

  .state-map-title{
    grid-column:1/2;
  }

  .state-map {
    grid-column: 1/2;
  }

  .adoption-header{
    grid-column: 1/2;
    grid-row: 7/8;
  }

  .broadband-header{
    grid-column: 1/2;
    grid-row: 9/10;
  }

  .availability-graph{
    width: 87vw;
  }

  .adoption-graph{
    grid-column: 1/2;
    grid-row: 8/9;
    width: 87vw;
  }

  .broadband-graph{
    grid-column: 1/2;
    grid-row: 10/11;
    width: 87vw;
  }

  .notes {
    grid-column: 1/2;
    grid-row: 11/12;
  }

  .full-site {
    grid-column: 1/2;
  }

}

@media (max-width: 600px) {
  .staticstate {
    grid-template-rows: 20pt 80pt 50pt 1fr 25pt 65vw 25pt 65vw 25pt 65vw 1pt 40pt;
  }

  .state-map {
    margin-bottom: calc(10vw - 100px);
  }
}

.state-slect {
  display: inline-block;
  margin: 0 0.25em;
  border: none;
  outline: none;
  box-shadow: none;
  min-height: none;
  /*cursor: pointer; */
  padding: 0;
  position: relative;
  transform: none;
  max-width: none;
  appearance: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  font-size: 1.1em;
  font-weight: bold;
  margin-top: -0.7em;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  padding: 1rem;
  padding-right: 1rem;
}

/*.state-slector {
  display: inline-block;
  margin: 0 0.25em;
  border: none;
  box-shadow: none;
  min-height: none;
  /* cursor: pointer; 
  padding: 0;
  position: relative;
  transform: none;
  max-width: none;
  font-size: 1.1em;
  font-weight: bold;
  margin-top: -0.7em;
  padding: 1rem;
  padding-right: 1rem;
} */

.state-slector {
  /* Basic sizing and positioning */
  display: inline-block;
  padding: 1rem 3rem 1rem 1rem; /* Added padding-right for arrow space */
  margin: 0 0.25em;
  margin-top: -0.7em;
  
  /* Font styling */
  font-size: 1.1em;
  font-weight: bold;
  
  /* Clean modern look */
  background-color: #f1f1f1; /* Light gray background */
  border: none;
  border-radius: 8px; /* Rounded corners */
  
  /* Remove default styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  
  /* Custom dropdown arrow */
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem top 50%;
  background-size: 0.65em auto;
  
  /* Hover and focus states */
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.state-slector:hover {
  background-color: #e9e9e9;
}

.state-slector:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0,0,0,0.1);
}

/* Remove default arrow in IE */
.state-slector::-ms-expand {
  display: none;
}

.stateSelector {
  grid-column: 2/7;
  grid-row: 2/5;
  display: grid;
  align-content: center;
  grid-template-columns: 10vw 70vw 10vw;
  grid-template-rows: 20pt 120pt 80pt 40pt;
}

.state-selector-program-title {
  grid-column: 2/3;
  grid-row: 2/3;
  text-align: center;
  margin-bottom: 5pt;
}

.state-selector-title {
  grid-column: 2/3;
  grid-row: 3/4;
  text-align: center;
}

.state-selector {
  grid-column: 2/3;
  grid-row: 4/5;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
}

/* @media (max-width: 866px) {
  .stateSelector {
    grid-template-rows: 20pt 150pt 80pt 40pt;
  }
}

@media (max-width: 632px){
  .stateSelector {
    grid-template-rows: 20pt 180pt 80pt 40pt;
  }
} */

@media (max-width: 866px){
  .stateSelector {
    grid-template-columns: 1fr;
    grid-template-rows: 20pt 120pt 80pt 40pt;
  }

  .state-selector-program-title{
    grid-column: 1/2;
  }

  .state-selector-title{
    grid-column: 1/2;
  }
  .state-selector {
    grid-column: 1/2;
  }
}

.simple-top-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 16pt 1fr 1fr 1fr 1fr;
  grid-gap: 0px;
  width: 100%;
  height: 100%;
}

.simple-header{
  grid-column: 2/6;
  grid-row: 1/2;
  text-align: center;
}

.simple-logout{
  grid-column: 3/4;
  grid-row: 2/3;
}

.simple-change-password{
  grid-column: 4/5;
  grid-row: 2/3;
}

.simple-description{
  grid-column: 2/6;
  grid-row: 3/4;
  text-align: center;
}

.simple-state-label{
  grid-column: 3/4;
  grid-row: 4/5;
  text-align: center;
}

.simple-state-select{
  grid-column: 4/5;
  grid-row: 4/5;
  text-align: center;
}

.simple-area-label{
  grid-column: 3/4;
  grid-row: 5/6;
  text-align: center;
}

.simple-area-select{
  grid-column: 4/5;
  grid-row: 5/6;
  text-align: center;
}

.simple-subarea-label{
  grid-column: 3/4;
  grid-row: 6/7;
  text-align: center;
}

.simple-subarea-select{
  grid-column: 4/5;
  grid-row: 6/7;
  text-align: center;
}

.simple-sign-in{
  text-align: center;
}

.data-download {
  display: grid;
  grid-template-columns: 5vw 90vw 5vw;
  grid-template-rows: 40pt 30pt 25pt 1fr 140pt 70pt;
}

.data-download-state-only {
  display: grid;
  grid-template-columns: 5vw 45vw 45vw 5vw;
  grid-template-rows: 40pt 30pt 30pt 30pt 30pt 5pt 1fr 5pt 140pt 70pt;
}

.area-selector-title-downloader{
  grid-column: 2/3;
  grid-row: 2/3;
}

.area-selector-signin-buttons{
  grid-column: 3/4;
  grid-row: 2/3;
}

.area-selector-dropdown-geography-downloader{
  grid-column: 2/3;
  grid-row: 3/4;
}

.area-selector-dropdown-state-downloader-title{
  grid-column: 2/3;
  grid-row: 4/5;
}

.area-selector-dropdown-state-downloader{
  grid-column: 2/3;
  grid-row: 5/6;
}

.downloader-checkboxes{
  grid-column: 2/3;
  grid-row: 4/5;
}

.downloader-options{
  grid-column: 2/3;
  grid-row: 5/6;
}

.downloader-button{
  grid-column: 2/3;
  grid-row: 6/7;
  height: 20px;
  width: 80px;
}

.data-download-state-only-checkboxes{
  grid-column: 2/4;
  grid-row: 7/8;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.data-download-state-only-options{
  grid-column: 2/3;
  grid-row: 9/10;
}

.data-download-state-only-button{
  grid-column: 2/3;
  grid-row: 10/11;
  height: 20px;
  width: 120px;
}

.bottom-rule {
  grid-column: 2/3;
  grid-row: 8/9;
  border-top: 2px solid #000;
  width: 100%;
}

.top-rule {
  grid-column: 2/3;
  grid-row: 6/7;
  border-top: 2px solid #000;
  width: 100%;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  z-index: 1;
  top: -5px;
  left: 105%; 

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}